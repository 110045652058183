import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';

import { Contact } from '../../../generated/types';
import { Colors } from '../../../styles/colors';

import { Form } from '../../atoms/Form/Form';
import { PrimaryButton, SecondaryButton, WrapperButtons } from '../../atoms/Button/Buttons';
import { H2 } from '../../atoms/Typography/Headings';
import { InfoAlert } from '../../atoms/InfoAlert';
import { TextInput } from '../../atoms/Form/TextInput';
import { Copy } from '../../atoms/Typography';
import { ChevronRightIcon } from '../../atoms/Icons';

export const AddContactModal = ({ contact, handleCancel, handleSubmit }: AddContactModalProps) => {
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    handleSubmit(data, contact ? contact : undefined);
  };

  const { fullName, position, email, phoneNumber, linkedin } = contact || {};

  return (
    <>
      {contact ? <H2>Edit Contact</H2> : <H2>Add Contact</H2>}
      <InfoAlert>
        {contact ? (
          <Copy>You can edit the contact related to this Job Opportunity.</Copy>
        ) : (
          <Copy>You can add a contact related to this Job Opportunity.</Copy>
        )}
      </InfoAlert>
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <TextInput
          name="fullName"
          fullWidth
          type="text"
          label="Full name"
          required
          defaultValue={fullName}
          noMargin={true}
        />
        <TextInput name="position" fullWidth type="text" label="Position" defaultValue={position} noMargin={true} />
        <TextInput name="email" fullWidth type="email" label="Email" defaultValue={email} noMargin={true} />
        <TextInput
          name="phoneNumber"
          fullWidth
          type="text"
          label="Phone number"
          defaultValue={phoneNumber}
          noMargin={true}
        />
        <TextInput
          name="linkedin"
          fullWidth
          type="text"
          label="LinkedIn profile"
          defaultValue={linkedin}
          noMargin={false}
        />

        <WrapperButtons>
          <SecondaryButton inline onClick={handleCancel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton type="submit" inline={true} iconRight={<ChevronRightIcon color={Colors.White} size={1} />}>
            {contact ? 'Update' : 'Add'}
          </PrimaryButton>
        </WrapperButtons>
      </Form>
    </>
  );
};

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required().min(1, 'Too short').max(50, 'Too Long!'),
  position: Yup.string().max(50, 'Too Long!'),
  email: Yup.string().email(),
  phoneNumber: Yup.string().max(50, 'Too Long!'),
  linkedin: Yup.string().max(80, 'Too Long!'),
});

type FormData = Yup.InferType<typeof validationSchema>;

interface AddContactModalProps {
  contact?: Contact;
  handleSubmit: (a: any, contact?: Contact) => void;
  handleCancel: (a: any) => void;
}
